<template>
    <div>
        <div class="line"></div>
        <AttendeeList
        :list="list"
        :meetId="meetId"  
        :meetStatus='meetInfo.meetStatus'    
        :applyField="applyField"
        :ifDelete='false'
      />
    </div>
</template>

<script>
import {getAttenderList,getMeetInfo} from "@/api/meet.js"
import AttendeeList from "../meet/component/attendeeList.vue";

    export default {
        name:'myMemberList',
        components:{
            AttendeeList
        },
        data(){
            return{
                orderId:'',
                meetId:'',
                list:[],
                applyField:'',
                meetInfo:{},
            }
        },
        created(){   
            let {orderId,meetId} = this.$route.query  
            this.orderId=orderId;
            this.meetId=meetId;
            this.getAttenderList(orderId);
            this.getMeetInfo(meetId)
        },
        methods:{
            getAttenderList(orderId){
                getAttenderList({orderId}).then(res=>{
                    if(res.status=="S"){
                        this.list=res.data
                    }
                })
            },
            getMeetInfo(id){
                getMeetInfo({id}).then(res=>{
                    if(res.status=="S"){
                        this.applyField=res.data.applyField
                        this.meetInfo=res.data;
                    }
                })
            },
        }
    }
</script>

<style lang="less" scoped>
.line{
    width: 100%;;
    height: 0.1rem;
    background: #f5f5f5;
}
</style>